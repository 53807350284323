<template>
  <div class="report-journal">
    <el-table
      :data="[]"
      stripe>
      <el-table-column
        prop="created_at"
        label="Установлен">
        <template #default="scope">
          {{ $filters.convertDate(scope.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="status_after_details.short_name"
        label="Статус" />
      <el-table-column
        prop="user"
        label="Сотрудник">
        <template #default="scope">
          {{ scope.row.user ? scope.row.user.name : '-' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="message"
        label="Примечание">
        <template #default="scope">
          {{ scope.row.message || '-' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="reasonText"
        label="Причина возврата">
        <template #default="scope">
          {{ scope.row.reasonText || '-' }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'ReportJournal',
  computed: {
  }
}
</script>
